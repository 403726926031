<template>
  <div
    style="height: 100%;"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="px-0"
        >
          <!-- BLOCK DESKTOP -->
          <div
            class="hidden-xs-only"
          >
            <group-documents-header
              show-reload-resource
              :loading-reload-resource="loading.reloadResource"
              @clickCreateDocument="isShow.create = true"
              @clickCreateFolder="isShow.folder_create = true"
              @clickCreateFile="isShow.file_create = true"
              @clickReloadResource="getData"
            />

            <group-card
              section-name="Spazio condiviso"
            />

            <v-divider></v-divider>

            <!-- GROUP FOLDERS -->
            <v-row class="mt-3">
              <v-col
                v-for="(folder,idx) in level0Folders"
                :key="'F'+idx"
                cols="12"
                sm="12"
                md="3"
              >
                <folder-card
                  is-plugin-version
                  folder-route="pluginGroupFolderView"
                  :folder-obj="folder"
                  :folder-permissions="folder.group_folder_permissions"
                  :go-to-folder-target-id="folder.group_id"
                  :api-routes="apiRoutes"
                  :api-target-id="folder.group_id"
                  @reloadData="getData"
                  @droppedItem="setIsDropped"
                  @droppedFile="setIsDroppedFile"
                />
              </v-col>
            </v-row>

            <!-- GROUP DOCUMENTS -->
            <v-row>
              <v-col
                v-for="(doc,idx) in level0Documents"
                :key="idx"
                cols="12"
                sm="12"
                md="4"
              >
                <group-documents-card
                  is-draggable
                  :document-obj="doc"
                  document-route="pluginGroupDocumentFiles"
                  is-plugin-version
                  :is-dropped="isDropped[doc.id]"
                  @reload-data="getData"
                />
              </v-col>
            </v-row>

            <!-- GROUP FILES -->
            <v-row>
              <v-col
                v-for="(file, idxF) in level0Files"
                :key="idxF"
                cols="12"
                sm="12"
                md="3"
              >
                <file-card
                  no-thumb
                  is-plugin-version
                  can-handle-file
                  :is-dropped="isDroppedFile[file.id]"
                  :is-draggable="true"
                  :file-type="'group'"
                  :procedure-id="1"
                  :file-obj="file"
                  :download-url="downloadUrl(file.id)"
                  @reloadData="getData"
                />
              </v-col>
            </v-row>
          </div>

          <div
            class="hidden-sm-and-up"
          >
<!--            <group-documents-header
              show-reload-resource
              :loading-reload-resource="loading.reloadResource"
              @clickCreateDocument="isShow.create = true"
              @clickCreateFolder="isShow.folder_create = true"
              @clickCreateFile="isShow.file_create = true"
              @clickReloadResource="getData"
            />-->

            <mobile-group-entities-list
              folder-route="pluginGroupFolderView"
              list-title="Spazio condiviso"
              :is-page-fully-loaded="isPageFullyLoaded"
              :folders="level0Folders"
              :files="level0Files"
            />
          </div>

          <!-- MODALS INITIALISATION -->
          <create-group-folder
            v-if="isOwner"
            is-plugin-version
            :is-show="isShow.folder_create"
            :group-id="loggedUserGroup.id"
            @close="isShow.folder_create=false"
            @reload-data="getData"
          />
          <create-group-document
            v-if="isOwner"
            no-auto-import
            no-priority
            :is-show="isShow.create"
            @close="isShow.create=false"
            @reload-data="getData"
          />
          <create-group-file
            v-if="isOwner"
            no-auto-import
            no-priority
            :is-show="isShow.file_create"
            :upload-url="uploadUrl"
            @close="isShow.file_create=false"
            @reload-data="getData"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-speed-dial
      class="hidden-sm-and-up"
      absolute
      fab
      bottom
      right
      direction="top"
      transition="slide-y-reverse-transition"
      v-model="fab"
    >
      <template v-slot:activator>
        <v-btn
          :color="btnColor"
          dark
          fab
          :loading="loading.reloadResource"
        >
          <v-icon
            v-if="fab"
          >
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        small
        color="green"
        @click="isShow.folder_create = true"
      >
        <v-icon>mdi-folder-plus</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="indigo"
        @click="isShow.file_create = true"
      >
        <v-icon>mdi-file-plus</v-icon>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import GroupDocumentsHeader from '@/components/section-headers/group-documents-header.vue'
import GroupCard from '@/components/cards/group-card.vue'
import GroupDocumentsCard from '@/components/cards/group-documents-card.vue'
import CreateGroupDocument from '@/components/modals/create-group-document.vue'
import CreateGroupFolder from '@/components/modals/create-group-folder.vue'
import CreateGroupFile from '@/components/modals/create-group-file.vue'
import FolderCard from '@/components/cards/folder-card.vue'
import FileCard from '@/components/cards/file-card.vue'
import MobileGroupEntitiesList from "@/components/lists/mobile-group-entities-list.vue";

export default {
  name: 'PluginGroup',
  components: {
    MobileGroupEntitiesList,
    FileCard,
    FolderCard,
    CreateGroupDocument,
    GroupDocumentsCard,
    GroupCard,
    GroupDocumentsHeader,
    CreateGroupFolder,
    CreateGroupFile,
  },
  data() {
    return {
      isDropped: {},
      isDroppedFile: {},
      isPageFullyLoaded: false,
      isShow: {
        create: false,
        edit: false,
        destroy: false,
        folder_create: false,
        file_create: false,
      },
      edit_doc_obj: {},
      pluginSettings: {
        resourceFound: null,
      },
      loading: {
        reloadResource: false,
      },
      fab: false,
      apiRoutes: {
        editApiMethod: 'editPluginFolderGroup',
        deleteApiMethod: 'destroyPluginFolderGroup',
        editDocumentApiMethod: 'editGroupDocument',
        permGetApiMethod: 'getGroupFolderPermissions',
        permSetApiMethod: 'setGroupFolderPermission',
        permEditApiMethod: 'updateGroupFolderPermission',
        permDeleteApiMethod: 'deleteGroupFolderPermission',
        editFileApiMethod: 'editGroupFile',
      },
    }
  },
  computed: {
    ...mapGetters(['token', 'loggedUser', 'loggedUserGroup', 'partnerSettings', 'groupDocumentsList', 'groupFolders', 'groupFilesList']),
    isOwner() {
      return this.loggedUser.company_role === 'owner'
    },
    level0Folders() {
      if (this.groupFolders) {
        return this.groupFolders.filter(item => item.parent_id === 0)
      }

      return []
    },
    level0Documents() {
      if (this.groupDocumentsList) {
        return this.groupDocumentsList.filter(item => item.folder_id === 0)
      }

      return []
    },
    level0Files() {
      if (this.groupFilesList) {
        return this.groupFilesList.filter(item => item.group_folder_id === 0)
      }

      return []
    },
    uploadUrl() {
      return `${this.$apiUrl}ext/group-files/0/${this.loggedUserGroup.id}`
    },
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
  },
  async created() {
    // if the user token is not set in the vuex store, set it. Also reset if the token is different from the one in the url
    if (this.$route.query.token && (!this.token || this.token !== this.$route.query.token)) {
      await this.$store.commit('resetStore')

      // dispatch loginToken action and pass the token as a parameter. The token can be retrieved from the url
      await this.$store.dispatch('loginToken', this.$route.query.token)
    }

    // check if user exists as a key in the vuex store (if not, it means that the user is not logged in)
    // check also if this.loggedUser is an empty array
    if (!this.loggedUser || !Object.keys(this.loggedUser).length) {
      await this.$store.dispatch('getPartnerSettings')
        .then(() => this.$store.dispatch('getPluginCompanySettings'))
        .then(() => this.$store.dispatch('getPluginUserInfoAll'))
        .then(() => this.$store.dispatch('getLicense'))
        .then(() => this.$store.dispatch('getGroup'))
        .then(() => this.$store.dispatch('getGroupDocuments'))
        .then(() => this.$store.dispatch('getGroupFiles'))
        .then(() => this.getData())
        .then(() => this.setResourceFound(true))
        .then(() => this.setPageIsFullyLoaded(true))
        .catch(error => {
          console.log(error)
        })
    } else {
      await this.setPageIsFullyLoaded(true)
    }
  },
  async mounted() {
    if (this.isPageFullyLoaded) {
      try {
        await this.getData()
        await this.setResourceFound(true)
      } catch (error) {
        if (error.status === 404) {
          await this.setResourceFound(false)
        } else {
          console.log(error)
        }
      }
    }
  },
  methods: {
    async getData() {
      this.loading.reloadResource = true
      await this.$store.dispatch('getPluginUserInfoAll')
      await this.$store.dispatch('getGroup', this.loggedUserGroup.id)
      await this.$store.dispatch('getGroupDocuments')
      await this.$store.dispatch('getGroupFiles')
      this.isDropped = {}
      this.isDroppedFile = {}
      this.loading.reloadResource = false
    },
    setModalDocument(docId) {
      this.upload_url = `${this.$apiUrl}group-documents/${this.loggedUserGroup.id}/documents/${docId}`
    },
    async setModalDocumentObj(docObj) {
      this.edit_doc_obj = await docObj
    },
    async setResourceFound(status) {
      this.pluginSettings.resourceFound = status
    },
    async setPageIsFullyLoaded(status) {
      this.isPageFullyLoaded = status
    },
    setIsDropped(documentId) {
      this.$set(this.isDropped, documentId, true)
    },
    setIsDroppedFile(fileId) {
      this.$set(this.isDroppedFile, fileId, true)
    },
    downloadUrl(fileId) {
      return `${this.$apiUrl}ext/group-files/${this.loggedUserGroup.id}/files/${fileId}`
    },
  },
}
</script>

<style scoped>
.container {
    max-width: 90%;
}
</style>
